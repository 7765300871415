/* eslint-disable prettier/prettier */
import { yupResolver } from '@hookform/resolvers/yup';
import { forwardRef, memo, useEffect, useMemo } from 'react';
import type { FC, Ref } from 'react';
import { Spinner } from '@pulse-web-ui/spinner';
import { Button } from '@pulse-web-ui/button';
import { Controller, useForm } from 'react-hook-form';
import { v4 as uuid } from 'uuid';
import { useTranslation } from 'react-i18next';
import { Checkbox } from '@pulse-web-ui/checkbox';
import { HeaderWithSubText } from '@pulse-web-ui/header-with-sub-text';
import {
  Form,
  Row,
  AccordionSubLabel,
  AccordionLabel,
  DocumentsWrapper,
  ListWrapper,
} from './documents-list.style';
import type {
  DocumentsListOptions,
  DocumentsListValues,
} from './documents-list.type';
import { ClientDocumentItem } from './components/client-document/client-document';
import { Accordion } from '@pulse-web-ui/accordion';
import OptionsList from 'smart-components/options-list';
import { SmartComponentProps } from 'smart-components/shared/types';
import type { DocumentType } from '@shared/types';

import { i18nDefaultValues } from './i18n';
import { getDocumentsListSchema } from './schema';
import { useHandleAgentSubmit } from './use-handle-agent-submit';
import { LabelValue } from '@shared/components';
import {
  addTestAttribute,
  phoneDisplayValueCasting,
  phoneValueCasting,
} from '@shared/utils';

const accordionId = uuid();

const DocumentsList: FC<
  SmartComponentProps<DocumentsListValues, DocumentsListOptions>
> = memo(
  forwardRef(
    (
      {
        value: valuesFromProp,
        onChange,
        isSubmitting,
        disabled,
        options: {
          risks,
          currency,
          agentLogin,
          coverageSum,
          insurantInfo,
          initStateRisks,
          selectedRisksLabel,
          setActiveStepByName,
        },
      },
      forwardRef: Ref<HTMLDivElement>
    ) => {
      const { t } = useTranslation();
      const schema = useMemo(() => getDocumentsListSchema(), [t]);
      const {
        trigger,
        clearErrors,
        control,
        watch,
        setValue,
        formState: { isValid, isDirty },
        getValues,
      } = useForm<DocumentsListValues>({
        values: valuesFromProp,
        resolver: yupResolver(schema),
        resetOptions: {
          keepDirty: true,
        },
        context: {},
      });

      const { submitOrder, isSubmitLoading } = useHandleAgentSubmit();

      useEffect(() => {
        if (isSubmitting) {
          trigger();
        }
      }, [isSubmitting]);

      useEffect(() => {
        const subscription = watch((values) => {
          if (isDirty) {
            onChange(values as DocumentsListValues);
          }
          clearErrors();
        });

        return () => subscription.unsubscribe();
      }, [watch, isDirty]);

      useEffect(() => {
        setValue('isValid', isValid);
      }, [isValid]);

      useEffect(
        () => () => {
          const values = getValues();
          if (values.acceptRequirements) {
            onChange({
              ...values,
              acceptRequirements: false,
              isValid: false,
            });
          }
        },
        []
      );

      const handleMoveOptionStep = () => {
        setActiveStepByName('options');
      };

      const ClientInformation = useMemo(() => {
        return (
          <>
            <ListWrapper>
              <LabelValue
                label={t('SMART:DocumentsList.labels.insurant')}
                value={insurantInfo.name}
                testId="order-insurant"
              />
              <LabelValue
                label={t('SMART:DocumentsList.labels.phone')}
                value={phoneDisplayValueCasting(
                  phoneValueCasting(insurantInfo.phone)
                )}
                testId="order-phone"
              />
              <LabelValue
                label={t('SMART:DocumentsList.labels.sendDocumentsToClient')}
                value={insurantInfo.email}
                testId="order-email"
              />
            </ListWrapper>
            <Row>
              <Button
                label={t('SMART:Submit.labels.send')}
                onClick={submitOrder}
                disabled={isSubmitLoading}
                {...addTestAttribute('travel-submit')}
              />
            </Row>
          </>
        );
      }, [insurantInfo]);

      return (
        <Form ref={forwardRef} {...addTestAttribute('documents-block')}>
          <Accordion
            value={<AccordionSubLabel>{selectedRisksLabel}</AccordionSubLabel>}
            label={
              <AccordionLabel>
                {t('SMART:DocumentsList.labels.includedInPolicy', {
                  defaultValue:
                    i18nDefaultValues.DocumentsList.labels.includedInPolicy,
                })}
              </AccordionLabel>
            }
            // TODO: добавить тип вида строки в компонент
            id={accordionId as any}
          >
            <OptionsList
              risks={risks}
              currency={currency}
              coverageSum={coverageSum}
              initStateRisks={initStateRisks}
              showButton={false}
              handleMoveOptionStep={handleMoveOptionStep}
            />
          </Accordion>
          {!!agentLogin ? (
            ClientInformation
          ) : (
            <>
              <DocumentsWrapper>
                <HeaderWithSubText
                  title={t('SMART:DocumentsList.title', {
                    defaultValue: i18nDefaultValues.DocumentsList.title,
                  })}
                />
                <Controller
                  render={({ field: { value } }) => (
                    <ListWrapper>
                      {value ? (
                        value?.map(
                          (
                            {
                              clientDocument,
                              clientDocumentType,
                            }: DocumentType,
                            index
                          ) => (
                            <ClientDocumentItem
                              key={`${index}${clientDocument}`}
                              clientDocument={clientDocument}
                              clientDocumentType={clientDocumentType}
                              token={valuesFromProp?.token}
                              index={index}
                            />
                          )
                        )
                      ) : (
                        <Spinner />
                      )}
                    </ListWrapper>
                  )}
                  control={control}
                  name="documentsList"
                />
              </DocumentsWrapper>
              <Row {...addTestAttribute('accept-terms-policy')}>
                <Controller
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <Checkbox
                      onChange={(e: any) => onChange(e.target.checked)}
                      disabled={disabled}
                      checked={value}
                      label={t('SMART:DocumentsList.labels.acceptTermsPolicy', {
                        defaultValue:
                          i18nDefaultValues.DocumentsList.labels
                            .acceptTermsPolicy,
                      })}
                      message={value ? '' : t(
                        'SMART:DocumentsList.errors.acceptRequirements',
                        i18nDefaultValues.DocumentsList.errors.acceptRequirements
                      )}
                      status={error ? 'error' : 'warning'}
                    />
                  )}
                  control={control}
                  name="acceptRequirements"
                />
              </Row>
            </>
          )}
        </Form>
      );
    }
  )
);

export default DocumentsList;
