import { lazy, Suspense } from 'react';
import {
  StyledBox,
  StyledContent,
  StyledImageBlock,
  StyledSaveButton,
  StyledShortBlock,
  StyledLabel,
  StyledText,
  StyledLongBlock,
  StyledLongLabel,
  StyledLongText,
  StyledParagraph,
  StyledCards,
  StyledCard,
  StyledCardImageBox,
  StyledCardText,
  StyledBottomLabel,
  StyledSocialButtons,
  StyledButtonText,
  StyledQRBox,
  StyledButton,
  StyledImg,
} from './payment-layout.styles';
import { Button } from '@pulse-web-ui/button';
import { WidgetContainer } from '@pulse-web-ui/containers';
import { Trans, useTranslation } from 'react-i18next';
import { SOCIAL } from '@shared/constants/social';
import { PaymentLayoutProps } from './payment-layout.types';
import { isBrowser } from 'react-device-detect';
import { domains } from '@shared/domains';
import { useIsDesktop } from '@shared/hooks';
import { isAppleSafari } from '@shared/utils/is-apple-safari';
import houseIcon from '@shared/icons/house.jpg';
import { addTestAttribute } from '@shared/utils';

const TelegramIcon = lazy(() => import('@shared/icons/telegram'));
const VkIcon = lazy(() => import('@shared/icons/vk'));
const DisplayQRButton = lazy(
  () => import('@pulse-web-ui/display-qr-button/lib/lazy')
);

export const PaymentLayout = ({
  policyText,
  policyLabel,
  items,
  isMobileApp,
  labelTest = 'payment-layout',
}: PaymentLayoutProps) => {
  const { t } = useTranslation();
  const isDesk = useIsDesktop();

  const handleClick = (path: string) => {
    window.open(path);
  };

  const handleProductClick = () => {
    window.open(`${domains.prod}/insurance-flat/`, '_blank');
  };

  const handleMobileAppClick = () => window.open(domains.mobileAppDeepLink);

  const handleMobileAppClickForBrowser = () => {
    const deepLink = window.envUrls.INSURANCE_MOBILE_APP_HOT_LINK;

    isAppleSafari()
      ? (window.location.href = deepLink)
      : window.open(deepLink, '_blank');
  };

  const shareLifehack = isDesk
    ? t('COMMON:pay.shareLifehack')
    : t('COMMON:pay.shareLifehackMobile');

  return (
    <>
      <WidgetContainer {...addTestAttribute(labelTest)}>
        <StyledShortBlock>
          <StyledBox>
            <StyledImageBlock>
              <StyledImg src={houseIcon} alt="apartment" />
            </StyledImageBlock>
            <StyledContent>
              <StyledLabel>{t('COMMON:pay.saveDuringTravel')}</StyledLabel>
              <StyledText>
                {t('COMMON:pay.amountOnPromocode')} <br />{' '}
                {t('COMMON:pay.onPromocode')} <b>{t('COMMON:pay.vacation')}</b>
              </StyledText>
            </StyledContent>
          </StyledBox>
          <StyledSaveButton onClick={handleProductClick} buttonSize="s">
            {t('COMMON:pay.save')}
          </StyledSaveButton>
        </StyledShortBlock>
      </WidgetContainer>
      <WidgetContainer>
        <StyledLongBlock>
          <StyledLongLabel>{policyLabel}</StyledLongLabel>
          <StyledLongText>{policyText}</StyledLongText>

          {isMobileApp && (
            <StyledButton
              variant="primary"
              isMobileApp={isMobileApp}
              label={t('COMMON:pay.backToMobileApp')}
              onClick={handleMobileAppClick}
            />
          )}

          {!isMobileApp && (
            <>
              {isBrowser ? (
                <StyledQRBox>
                  <Suspense>
                    <DisplayQRButton
                      buttonLabel={t('COMMON:pay.downloadApp')}
                      dropdownSubtitle={t('COMMON:pay.pointYourCamera')}
                    />
                  </Suspense>
                </StyledQRBox>
              ) : (
                <StyledButton
                  variant="primary"
                  label={t('COMMON:pay.downloadApp')}
                  onClick={handleMobileAppClickForBrowser}
                />
              )}
              <StyledParagraph>{t('COMMON:pay.inAppYouCan')}</StyledParagraph>
              <StyledCards>
                {items.map((item) => (
                  <StyledCard>
                    <StyledCardImageBox>{item.icon}</StyledCardImageBox>
                    <StyledCardText>{item.text}</StyledCardText>
                  </StyledCard>
                ))}
              </StyledCards>
              <StyledBottomLabel>
                <Trans>{shareLifehack}</Trans>
              </StyledBottomLabel>
              <StyledSocialButtons>
                <Button
                  variant="secondary"
                  onClick={() => handleClick(SOCIAL.telegram.path)}
                >
                  <Suspense>
                    <TelegramIcon />
                  </Suspense>
                  <StyledButtonText>
                    {t('COMMON:pay.telegram')}
                  </StyledButtonText>
                </Button>
                <Button
                  variant="secondary"
                  onClick={() => handleClick(SOCIAL.vk.path)}
                >
                  <Suspense>
                    <VkIcon />
                  </Suspense>
                  <StyledButtonText>
                    {t('COMMON:pay.vkontakte')}
                  </StyledButtonText>
                </Button>
              </StyledSocialButtons>
            </>
          )}
        </StyledLongBlock>
      </WidgetContainer>
    </>
  );
};
