import type { VZRProductConfig } from '../types';

export const DEFAULT_APP_CONFIG: VZRProductConfig = {
  name: 'travel-base-flow',
  header: [
    {
      name: 'Header',
    },
  ],
  footer: [
    {
      name: 'Footer',
    },
  ],
  submit: [
    {
      name: 'Submit',
    },
  ],
  flow: [
    {
      icon: 'World',
      name: 'calculation',
      components: [
        {
          name: 'WhereAndHowLong',
        },
        {
          name: 'WhoAndHow',
        },
      ],
    },
    {
      icon: 'Flash',
      name: 'options',
      components: [
        {
          name: 'WantImprovePolicy',
        },
      ],
    },
    {
      icon: 'Neighbors',
      name: 'formalization',
      components: [
        {
          name: 'WhoIssuesPolicy',
        },
        {
          name: 'WhoAndHowPaperwork',
        },
      ],
    },
    {
      icon: 'Wallet',
      name: 'payment',
      components: [
        {
          name: 'DocumentsList',
        },
        {
          name: 'B2P',
        },
      ],
    },
  ],
  globalVariables: {
    otpStep: 3,
    isShowBackButton: true,
  },
};
