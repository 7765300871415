/* eslint-disable indent */
import { ageRegEx } from '@shared/constants';
import { addTestAttribute, scrollToIframe } from '@shared/utils';
import { Suspense, lazy, useEffect, useState } from 'react';
import type { FC } from 'react';
import { v4 as uuid } from 'uuid';
import { useTranslation } from 'react-i18next';

import { AgeDropdownForm } from '@pulse-web-ui/age-dropdown';
import { HelperText } from '@pulse-web-ui/helper-text';
import { Input } from '@pulse-web-ui/input';
import { Modal } from '@pulse-web-ui/modal';
import { HeaderWithSubText } from '@pulse-web-ui/header-with-sub-text';

import { StyledLabelWrapper, StyledWrap } from './insured-persons.style';
import { i18nDefaultValues } from '../../i18n';
import type { Person, Props } from './insured-persons.type';

const ArrowDown = lazy(() => import('@pulse-web-ui/icons/lib/lazy/arrow-down'));

export const InsuredPersons: FC<Props> = ({
  value,
  onChange,
  error,
  maxPersons = 5,
  errors,
}) => {
  const { t } = useTranslation();
  const insurancePersonsDefaultData: Person = {
    id: uuid(),
    age: 0,
  };

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [insuredPersons, setInsuredPersons] = useState<Person[]>([
    insurancePersonsDefaultData,
  ]);

  useEffect(() => {
    if (isOpen) {
      let arr: Person[] = [];
      arr.push(insurancePersonsDefaultData);

      const hasZeroes =
        insuredPersons.filter((item) => item.age === 0).length > 0;

      if (value && value?.length > 0) {
        const tmp: Person[] = value.map((item) => {
          return {
            id: String(item.label ?? uuid()),
            age: Number.isNaN(Number(item.value)) ? 0 : Number(item.value),
          };
        });

        if (value?.length < maxPersons && !hasZeroes) {
          arr = [...tmp, insurancePersonsDefaultData];
        } else {
          arr = [...tmp];
        }
      }

      setInsuredPersons(arr);
    }
  }, [isOpen]);

  const handleModalSubmit = () => {
    const filteredPersons = insuredPersons.filter((items) => items.age !== 0);
    onChange(
      filteredPersons.map((item) => ({
        label: item.id,
        value: String(item.age),
      }))
    );
    setInsuredPersons(filteredPersons);
    setIsOpen(false);
  };

  const closeHandler = () => {
    setIsOpen(false);
  };

  const openHandler = () => {
    setIsOpen(true);
    scrollToIframe();
  };

  const addPerson = () => {
    setInsuredPersons((prevPersons) => {
      return [...prevPersons, { id: uuid(), age: 0 }];
    });
  };

  const deletePerson = (id: string) => {
    setInsuredPersons((prevPersons) => {
      return prevPersons.filter((person) => person.id !== id);
    });
  };

  const addAge = ({ id, age }: Person) => {
    if (ageRegEx.test(String(age))) {
      setInsuredPersons((prevPersons) => {
        return prevPersons.map((person) => {
          if (person.id === id) {
            return {
              ...person,
              age,
            };
          }

          return person;
        });
      });
    }
  };

  return (
    <HelperText
      noMargin
      status={error ? 'error' : 'default'}
      message={errors.ageSelect?.message}
    >
      <Input
        noMargin
        value={
          value && value?.length > 0 ? value.map((i) => i.value).join(', ') : ''
        }
        type="button"
        icon={
          <Suspense>
            <ArrowDown width={24} />
          </Suspense>
        }
        label={t('SMART:WhoAndHow.labels.ageTravelers', {
          defaultValue: i18nDefaultValues.WhoAndHow.labels.ageTravelers,
        })}
        onClickIcon={openHandler}
        onClick={openHandler}
        error={!!error}
        {...addTestAttribute('travel-tourist-age')}
      />
      <Modal
        showDialog={isOpen}
        closeIcon={false}
        submitHandler={handleModalSubmit}
        closeHandler={closeHandler}
        submitLabel={t('SMART:WhoAndHow.labels.apply', {
          defaultValue: i18nDefaultValues.WhoAndHow.labels.apply,
        })}
        dangerouslyBypassScrollLock
      >
        <StyledLabelWrapper>
          <HeaderWithSubText
            title={t('SMART:WhoAndHow.headers.indicateAge', {
              defaultValue: i18nDefaultValues.WhoAndHow.headers.indicateAge,
            })}
          />
        </StyledLabelWrapper>
        <StyledWrap>
          {insuredPersons.map((person, index) => (
            <AgeDropdownForm
              autoFocus
              key={person.id}
              person={person}
              addPerson={addPerson}
              deletePerson={deletePerson}
              addAge={addAge}
              label={t('SMART:WhoAndHow.labels.traveler', {
                defaultValue: i18nDefaultValues.WhoAndHow.labels.traveler,
              })}
              index={index}
              personLength={insuredPersons.length}
              maxPersons={maxPersons}
              testId={`traveler-${index + 1}`}
            />
          ))}
        </StyledWrap>
      </Modal>
    </HelperText>
  );
};
